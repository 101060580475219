import { useState, useEffect } from 'react'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from '../services'

export const useSelectQualifiedCredit = () => {
  const { setIsLoading } = useProposal()
  const { changeLeadData } = useLead()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [getLoading, setGetLoading] = useState(true)
  const [options, setOptions] = useState([])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'qualificacao' })
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        setGetLoading(true)

        const { previous_step, data } = await services.getQualification()
        setOptions(data)
        changeLeadData({
          previousStep: previous_step
        })
      } catch (error) {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectQualifiedCredit | getData',
          payload
        })
      } finally {
        setGetLoading(false)
      }
    }

    getData()
    console.log('qualification mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading])

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { qualification } = payload

    const requestBody = {
      id_qualification: Number(qualification)
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-qualificacao',
      detail: 'click-step-qualificacao',
      customLayer: {
        description: `credito-qualificado-id-${qualification}`
      }
    })

    return services
      .putQualification(requestBody)
      .then(({ next_step }) => {
        changeLeadData(requestBody)
        goTo(next_step)
      })
      .catch((error) => {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectQualifiedCredit | onSubmit',
          payload
        })
      })
      .finally(() => setSubmitLoading(false))
  }

  return { onSubmit, submitLoading, getLoading, options }
}
