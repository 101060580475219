import { useForm, useController } from 'react-hook-form'

import { IdsRadioButton } from '@ids/react'
import { Navigation, If } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import './select-qualified.scss'
import { useSelectQualifiedCredit } from './useSelectQualifiedCredit/index'

const SelectQualifiedCredit = ({ pageContext }) => {
  const initialValues = {
    qualification: ''
  }

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { field } = useController({
    name: 'qualification',
    control
  })
  const { onSubmit, submitLoading, getLoading, options } =
    useSelectQualifiedCredit()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={getLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 40px 0"
              width="80%"
              widthDesktop="48%"
              height="32px"
            />
            <Shimmer margin="10px 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer height="48px" />
            <Shimmer margin="20px 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer
              margin="170px 0 0 0"
              marginDesktop="120px 0 0 0"
              widthDesktop="50%"
              height="50px"
            />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title select-qualified-credit__title">
              O que é mais importante para você no crédito?
            </h1>
            <form
              className="form select-qualified-credit"
              onSubmit={handleSubmit(onSubmit)}
            >
              {options?.map(({ id, title, description }) => {
                return (
                  <IdsRadioButton
                    {...field}
                    key={id}
                    value={id}
                    name="noSelection"
                    onChange={(e) => field.onChange(e)}
                  >
                    <span key={id} className="select-qualified-credit__options">
                      {title}
                      <p className="select-qualified-credit__description">
                        {description}
                      </p>
                    </span>
                  </IdsRadioButton>
                )
              })}
              <Navigation
                submit
                nextStep
                isNextStepButtonLoading={submitLoading}
                isNextButtonDisabled={!field.value}
                nextButtonId="select-credit-qualified-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default SelectQualifiedCredit
