import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const putQualification = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'qualification',
    method: 'put',
    body: requestBody
  })

  return data
}

const getQualification = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'qualification',
    method: 'get'
  })

  return data
}

export default { putQualification, getQualification }
